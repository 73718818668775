export const breadcrumbList = [
  {
    name: '客户管理',
    path: ''
  },
  {
    name: '客户列表',
    path: ''
  }
]
export const breadcrumbSet = [
  {
    name: '客户管理',
    path: ''
  },
  {
    name: '客户列表',
    path: '/main/customer/list'
  },
  {
    name: '设置',
    path: ''
  }
]
