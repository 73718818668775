<template>
    <div class="top-content">
      <el-button style="height: 40px;"
            type="primary"
            size="mini"
            @click="handleSet">设置
        </el-button>
        <el-button class="add-staff"
            type="primary"
            size="mini"
            @click="handleAddStaffClick">
            <el-icon>
                <plus />
            </el-icon>新增会员
        </el-button>
        <div>
            <el-select clearable
                v-model="ruleForm.hascard"
                class="m-2"
                style="width: 130px"
                placeholder="类型"
                size="large">
                <el-option v-for="item in isHasCard"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"></el-option>
            </el-select>
        </div>
        <div>
            <el-select clearable
                v-model="ruleForm.gender"
                class="m-2"
                placeholder="性别"
                style="width: 80px"
                size="large">
                <el-option v-for="item in genderList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"></el-option>
            </el-select>
        </div>

        <div class="joinTime">
            <el-date-picker class="joinTime"
                @change="handleTimeChange"
                v-model="ruleForm.joinTime"
                type="daterange"
                style="width: 250px;"
                range-separator="到"
                start-placeholder="开始日期"
                end-placeholder="结束日期" />
        </div>
        <div>
            <el-select clearable
                v-model="ruleForm.shopid"
                class="m-2 shop"
                placeholder="所属门店"
                style="width: 180px"
                size="large">
                <el-option v-for="item in shopList"
                    :key="item.shopId"
                    :label="item.shopName"
                    :value="item.shopId"></el-option>
            </el-select>
        </div>
        <div>
            <el-select clearable
                v-model="ruleForm.regfrom"
                class="m-2"
                placeholder="客户来源"
                style="width: 120px"
                size="large">
                <el-option v-for="item in userLaiyaunList"
                    :key="item.id"
                    :label="item.sourceName"
                    :value="item.id"></el-option>
            </el-select>
        </div>
        <div>
            <el-input @change="handleSearchChange"
                class="search"
                clearable
                prefix-icon="search"
                v-model="ruleForm.keyword"
                placeholder="输入关键字" />
        </div>
        <div>
            <el-button class="add-staff"
                type="primary"
                @click="handleSearchClick">筛选</el-button>
        </div>

        <!-- <el-select v-model="ruleForm.level" class="m-2" placeholder="会员等级" size="large">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
    </el-select> -->

        <addClientDialog v-if="addClientDialogVisible"
            :dialogVisible="addClientDialogVisible"
            @changeDialogVisible="changeDialogVisible">
        </addClientDialog>

    </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute,useRouter } from 'vue-router'
import { COMPANY_ID } from '@/common/constant'
import { genderList, isHasCard } from '../config'
import dayjs from 'dayjs'
import emitter from '@/utils/eventbus'
import addClientDialog from './cpns/add-client-dialog/add-client-dialog'

export default defineComponent({
  props: {

  },
  components: {
    addClientDialog
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const ruleForm = reactive({
      keyword: '',
      hascard: '',
      level: '',
      gender: '',
      regStartTime: '',
      regEndTime: '',
      shopid: "",
      regfrom: '',
      joinTime: '',

    })

    const handleSearchChange = value => {
      if (value.length == 0) {
        handleSearchClick()
      }
    }

    const userLaiyaunList = computed(() => store.state.customer.userLaiyaunList)
    const shopList = computed(() => store.state.customer.shopList)

    const handleTimeChange = (value) => {
      // console.log(value);
      if (value == null) {
        ruleForm.regStartTime = ''
        ruleForm.regEndTime = ''
      } else {
        let startTime = dayjs(value[0]).format('YYYY-MM-DD')
        let endTime = dayjs(value[1]).format('YYYY-MM-DD')
        ruleForm.regStartTime = startTime + ' 00:00:00'
        ruleForm.regEndTime = endTime + ' 23:59:59'
      }

    }

    const handleSearchClick = () => {
      // console.log({ ...ruleForm });
      emitter.emit('changeCustomerTopContentSearch', { ...ruleForm })
    }

    const initPage = () => {
      store.dispatch('customer/getUserLaiyuanAction')
      store.dispatch('customer/getShopListAction')
    }
    initPage()


    const handleAddStaffClick = () => {
      addClientDialogVisible.value = true
    }

    const handleSet = () =>{
      router.push('/main/customer/set')
    }

    const addClientDialogVisible = ref(false)
    const changeDialogVisible = (flag) => {
      addClientDialogVisible.value = flag
    }


    return {
      addClientDialogVisible,
      changeDialogVisible,
      handleAddStaffClick,
      ruleForm,
      shopList,
      userLaiyaunList,
      genderList,
      handleTimeChange,
      handleSearchClick,
      isHasCard,
      handleSearchChange,
      handleSet
    }

  }
})
</script>

<style scoped lang="less">
@media screen and (max-width: 1560px) {
  .top-content {
    flex-wrap: wrap;

    & > div {
      margin-bottom: 10px;
    }
  }

  &:deep(.el-button) {
    margin-bottom: 10px;
  }
}

.top-content {
  display: flex;
  // justify-content: flex-end;
  flex-wrap: wrap;
  background-color: white;

  &:deep(.search) {
    min-width: 130px !important;
  }

  .joinTime {
    margin-right: 10px;
  }

  .add-staff {
    margin-right: 10px;
    height: 40px;
  }

  .search {
    width: 130px;
    // min-width: 130px;
    margin-right: 10px;
  }

  &:deep(.el-select) {
    margin-right: 10px;

    // .el-input {
    //   width: 150px;
    // }
  }
}
</style>




